import { navigate } from "gatsby";
import React, { FC } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import SigninWithCode from "../components/signin/with-code";
import { verifyNumber } from "../store/auth/actions";
import { useLocation } from "@reach/router";
import qs from "query-string";
import PhoneCodeType from "../types/phoneCodeType";

const CodeFromCart: FC = () => {
  const { search } = useLocation();
  const { type } = qs.parse(search);
  // used to determine which action is to be fired
  const isSignin = type === PhoneCodeType.SIGNIN;

  return (
    <Layout
      header={false}
      footer={false}
      menuState="cart"
      progressbar={true}
      progressBarStep={2}
    >
      <SEO title="Panier" />
      <SigninWithCode
        action={verifyNumber(isSignin ? "login" : "signup")}
        fromCart
        onSuccess={(): Promise<void> => navigate("/order?displayLoginStep=true")}
      />
    </Layout>
  );
};

export default CodeFromCart;
